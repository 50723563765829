<template>
  <div>
    <div class="d-flex-center justify-content-start">
      <div class="d-flex flex-column align-items-start mr-50">
        <div class="font-medium-3">
          <span>{{ `${paxIndex + 1}. ` }}</span>
          <code class="text-body font-italic bg-transparent pl-0 pr-25">
            {{ `${pax.paxType ? `(${$t(`reservation.${pax.paxType}`)})` : ''}` }}
          </code>
          <b>{{ `${pax.lastName} / ${pax.firstName} ${pax.title || ''} ` }}</b>
        </div>
      </div>

      <div
        v-if="['VJ', '1G', 'QH'].includes(reservationsData.source) && ['ADULT', 'CHILD'].includes(pax.paxType) && !isHideFunctionButton && !hideFeature"
        class="text-nowrap"
      >
        |
        <b-button
          variant="flat-info"
          class="text-info py-50 px-50"
          size="md"
          pill
          :disabled="isBookingOutSystem || reservationsData.status === 'CANCEL'"
          @click="$emit('openEditTravelFlyPassengerModalHandle')"
        >
          {{ $t('reservation.addTTV') }}
        </b-button>
      </div>

      <div
        v-if="SOURCES_CAN.REMOVE_INFANT.includes(reservationsData.source) && ['INFANT'].includes(pax.paxType) && !isHideFunctionButton && !hideFeature"
        class="text-nowrap"
      >
        |
        <b-button
          variant="flat-info"
          class="text-info py-50 px-50"
          size="md"
          pill
          :disabled="isBookingOutSystem || reservationsData.status === 'CANCEL'"
          @click="$emit('handleDeleteInfant')"
        >
          {{ $t('reservation.removeInfant') }}
        </b-button>
      </div>
    </div>

    <div>
      <!-- ANCHOR - Thẻ thành viên, bông sen vàng -->
      <small
        v-if="!isEmpty(pax.travelFlyer)"
        class="d-flex align-items-center ml-1"
      >
        <em>{{ $t('reservation.membershipCard') }}: </em>
        <span class="fw-700 mx-25">
          {{ pax.travelFlyer?.tier }}
          {{ pax.travelFlyer?.provider }}
          {{ pax.travelFlyer.number || pax.travelFlyer }}
        </span>
        <span
          v-if="pax.travelFlyer.tierDescription"
          class="font-weight-bolder"
        >
          ({{ pax.travelFlyer.tierDescription }})
        </span>
        <b-button
          v-if="['1S', '1S_CTRL', 'QH'].includes(reservationsData.source)"
          variant="flat-danger"
          size="md"
          class="p-25 ml-50"
          pill
          @click="$emit('deleteBSVHandle')"
        >
          {{ ['1S', '1S_CTRL'].includes(reservationsData.source) ? $t('reservation.addBSV') : $t('reservation.addTTV') }}
        </b-button>
      </small>

      <!-- ANCHOR - Passport -->
      <div
        v-if="shouldDisplayPassport(reservationsData.source, pax.paxType)"
        class="ml-1"
      >
        <div
          v-for="(doc, dIndex) in getPassport(pax)"
          id="reservation.Passport"
          :key="dIndex"
          class="d-flex align-items-center gap-1"
        >
          {{ `${getPassport(pax).length > 1
            ? `${$t('reservation.Passport')} ${dIndex+1}`
            : `${['VJ'].includes(reservationsData.source) ? $t('reservation.ppOrCccd') : $t('reservation.Passport')}` }` }}: {{ doc.number }} {{ doc.nationality }} {{ convertISODateTime(doc.endDate, 'UTC').date || doc.endDate }} {{ doc.isCountry }}
          <div
            v-if="flightDate && checkPassportLessThan6MonthsBeforeFlightDate(doc.endDate, flightDate)"
            class="text-danger"
          >
            <small>({{ $t('reservation.passportLessThan6MonthsBeforeFlight') }})</small>
          </div>
        </div>
      </div>

      <div
        v-if="['ADULT','INFANT', 'CHILD'].includes(pax.paxType)"
        id="reservation.Accompanying_adult"
        class="text-warning ml-1"
      >
        <div v-if="pax.birthday">
          {{ $t('reservation.DoB') }}: {{ convertISODateTime(pax.birthday, 'UTC').date }}
        </div>
        <div v-if="getAccompanyingPerson(reservationsData.paxLists, pax)">
          {{ $t(`reservation.Accompanying_${['INFANT'].includes(pax.paxType)?'adult':'infant'}`) }}
          {{ getAccompanyingPerson(reservationsData.paxLists, pax) }}
        </div>
      </div>
    </div>

    <!-- ANCHOR ancillaryServicesOfPax -->
    <div
      v-if="ancillaryServicesOfPax && ancillaryServicesOfPax.length"
      class="d-flex flex-column my-25 ml-1"
    >
      <span
        v-for="(ancillaryItem, ancillaryIndex) in ancillaryServicesOfPax"
        :key="ancillaryIndex"
        class="font-small-4"
      >
        {{ ancillaryItem }}
      </span>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
import { computed } from '@vue/composition-api'
import { isEqual, sortBy, groupBy } from 'lodash-es'

import { resolveSsrNameByCode } from '@/constants/selectOptions'
import { checkPassportLessThan6MonthsBeforeFlightDate, convertISODateTime, getMinTime } from '@/@core/utils/filter'
import { SOURCES_CAN } from '@/constants/reservation'

export default {
  components: {
    BButton,
  },
  props: {
    pax: {
      type: Object,
      required: true,
    },
    paxIndex: {
      type: Number,
      required: true,
    },
    reservationsData: {
      type: Object,
      required: true,
    },
    hideFeature: {
      type: Boolean,
      default: false,
    },
    isHideFunctionButton: {
      type: Boolean,
      default: false,
    },
    isBookingOutSystem: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root }) {
    const shouldDisplayPassport = (source, paxType) => ['AK'].includes(source) || (['VJ', 'VN1A', 'VN1A_MT', '1A', 'TH', 'TR', 'JQ', '1G'].includes(source) && ['ADULT', 'CHILD'].includes(paxType))

    function getPassport(pax) {
      if (pax.document && !isEmpty(pax.document)) {
        return pax.document.filter(doc => doc.docType && ['P', 'C'].includes(doc.docType))
      }
      return []
    }

    function getAccompanyingPerson(paxLists, pax) {
      if (pax.paxType === 'INFANT' && pax.parentPaxId) {
        const father = paxLists.find(p => p.paxId === pax.parentPaxId)
        return father ? `${father.lastName} ${father.firstName}` : ''
      }

      if (pax.paxType === 'ADULT') {
        const infant = paxLists.find(p => p.parentPaxId === pax.paxId)
        return infant ? `${infant.lastName} ${infant.firstName}` : ''
      }

      return ''
    }

    function resolveNameTripMultiSegments(segments) {
      const segmentNames = segments.map(segment => `${segment.departure.iataCode}-${segment.arrival.iataCode}`)
      const flattenedNames = segmentNames.join('-').split('-')
      const uniqueNames = flattenedNames.filter((code, index) => code !== flattenedNames[index + 1])
      return uniqueNames.join('-')
    }

    const ancillaryServicesOfPax = computed(() => {
      const { itineraries, source } = props.reservationsData

      function mapTrip(serviceItem) {
        let resultTrip
        if (serviceItem.serviceType === 'BAGGAGE') {
          resultTrip = ['TH'].includes(source)
            ? serviceItem.segmentIds.join(', ')
            : ['VJ', 'TR', 'JQ'].includes(source)
              ? resolveNameTripMultiSegments(itineraries.flat().filter(seg => serviceItem.segmentIds.some(itemSegment => itemSegment === seg.segmentId)))
              : ['AK'].includes(source)
                ? itineraries.flatMap(trip => trip.map(seg => seg)).filter(s => serviceItem.segmentIds[0] === s.segmentId)
                : itineraries.filter(trip => isEqual(sortBy(serviceItem.segmentIds), sortBy(trip.map(s => s.segmentId)))).flat()
        } else if (serviceItem.serviceType === 'SEAT') {
          resultTrip = ['VJ', 'TR', 'JQ'].includes(source)
            ? resolveNameTripMultiSegments(itineraries.flat().filter(seg => serviceItem.segmentIds.flatMap(id => id.split(' | ')).includes(seg.segmentId)))
            : [itineraries.flat().find(s => s.segmentId === serviceItem.segmentIds[0])]
        } else {
          resultTrip = ['TH'].includes(source)
            ? serviceItem.segmentIds.join(', ')
            : ['VJ', 'TR', 'JQ'].includes(source)
              ? resolveNameTripMultiSegments(itineraries.flat().filter(seg => serviceItem.segmentIds.some(itemSegment => itemSegment === seg.segmentId)))
              : [itineraries.flat().find(s => s.segmentId === serviceItem.segmentIds[0])]
        }

        // show
        if (['VJ', 'TH', 'TR', 'JQ'].includes(source)) {
          return resultTrip
        }
        if (!isEmpty(resultTrip) && resultTrip.length < 2) {
          return resultTrip[0] ? `${resultTrip[0]?.departure?.iataCode}-${resultTrip[0]?.arrival?.iataCode}` : ''
        }
        if (!isEmpty(resultTrip) && resultTrip.length > 1) {
          return resultTrip.map(i => `${i.departure?.iataCode}-${i.arrival?.iataCode}`).join(', ')
        }
        return resultTrip
      }

      let result = []
      if (props.reservationsData.ancillaryServices && !isEmpty(props.reservationsData.ancillaryServices)) {
        result = props.reservationsData.ancillaryServices
          .filter(ancillary => (ancillary.paxId === props.pax.paxId) && (props.pax.paxType !== 'INFANT'))
          .map(serviceItem => ({
            trip: mapTrip(serviceItem),
            name: serviceItem.serviceType === 'BAGGAGE'
              ? (
                ['VJ'].includes(source)
                  ? `${resolveSsrNameByCode(serviceItem.serviceInfo?.description)} x${serviceItem.numberOfItems}`
                  : `${resolveSsrNameByCode(['VN1A_MT', 'VN1A', 'TH', 'TR', 'JQ'].includes(source) ? serviceItem.serviceCode : serviceItem.serviceSubCode)} x${serviceItem.numberOfItems}`
              )
              : serviceItem.serviceType === 'SEAT'
                ? `${root.$t('flight.Seat')} ${serviceItem?.pdcSeat ?? serviceItem?.serviceInfo?.description ?? ''}`
                : ['VJ'].includes(source)
                  ? resolveSsrNameByCode(serviceItem.serviceInfo.description)
                  : ['VN1A', 'VN1A_MT'].includes(source)
                    ? resolveSsrNameByCode(serviceItem.serviceSubCode || serviceItem.serviceCode)
                    : ['TR', 'JQ'].includes(source)
                      ? resolveSsrNameByCode(`${source}_${serviceItem.serviceCode}`)
                      : (serviceItem.serviceInfo.description || resolveSsrNameByCode(serviceItem.serviceSubCode || serviceItem.serviceCode)),
          }))
      }
      const group = groupBy(result, 'trip')
      return Object.keys(group).map(i => `${i ? `${i}: ` : ''}${group[i].map(s => s.name).join('; ')}`)
    })

    const flightDate = computed(() => {
      const allDepartureDate = props.reservationsData?.itineraries?.flat()?.map(item => item?.departure?.at)
      return getMinTime(allDepartureDate)
    })

    return {
      shouldDisplayPassport,
      isEmpty,
      convertISODateTime,
      getPassport,
      getAccompanyingPerson,
      ancillaryServicesOfPax,
      SOURCES_CAN,
      checkPassportLessThan6MonthsBeforeFlightDate,
      flightDate,
    }
  },
}
</script>
<style lang="">

</style>
